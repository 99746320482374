import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { GrDownload } from "react-icons/gr";
import { FaApple, FaWindows } from "react-icons/fa";
import { FiCopy } from "react-icons/fi";
import RecantLogo from "../../assets/images/RecantNewLogo.png";
import { toast } from "sonner";

const BeeperComponent = ({ handleCloseModal, showModal }) => {
  const [copied, setCopied] = useState(false);
  let matrixUser = localStorage.getItem("matrixUser");
  const extractedMatrixUser =
    matrixUser !== "null"
      ? matrixUser?.substring(1, matrixUser?.indexOf(":"))
      : null;
  const copyToClipboard = async (value) => {
    await navigator.clipboard.writeText(value);
    toast.info("copied to clipboard");
    setCopied(true);
  };
  const downloadPackage = (build) => {
    if (build === "windows") {
      toast.error("Windows is Coming Soon 😉");
      return;
    }

    const packageURL = `https://recant-desktop-packages.s3.ap-southeast-2.amazonaws.com/recant-desktop-${build}.zip`;

    const link = document.createElement("a");
    link.href = packageURL;
    link.download = "recant-desktop";
    link.click();
  };
  return (
    <Modal
      show={showModal}
      onHide={handleCloseModal}
      centered
      className='flex items-center justify-center z-50'
      backdrop='static'
      keyboard={false}
    >
      <div className='w-full flex flex-col items-center justify-center bg-gray-100 rounded-lg p-10 shadow-lg'>
        {/* Logo */}
        <div className='mb-6'>
          <img src={RecantLogo} alt='Logo' className='h-12' />
        </div>

        {/* Download Icon */}
        <div className='mb-4 text-primaryColor'>
          <GrDownload fontSize={40} />
        </div>

        {/* Heading */}
        <div className='text-center px-8 mb-6'>
          <h2 className='text-2xl font-semibold text-gray-800'>Get Started</h2>
          <p className='text-sm text-[#787575] mt-2 font-normal'>
            To begin, download and set up Recant on your desktop. Recant is
            compatible with Mac and Windows.
          </p>
        </div>

        {/* Download Instructions */}
        <div className='text-md text-gray-700 text-left text-[#787575]'>
          <ol className='space-y-3 list-decimal list-inside pl-0'>
            <li>
              Download the app for your platform:
              <div className='flex flex-col justify-center space-y-4 mt-4'>
                {/* Mac Download Button */}
                <button
                  className='py-3 px-6  text-primaryColor rounded-lg font-medium shadow-sm border-1  hover:border-hoverPrimary  flex items-center space-x-3'
                  onClick={() => downloadPackage("macos-amd64")}
                >
                  <FaApple className='text-primaryColor text-2xl' />
                  <span>macOS (Apple Silicon)</span>
                </button>

                <button
                  className='py-3 px-6  text-primaryColor rounded-lg font-medium shadow-sm border-1 hover:border-hoverPrimary  flex items-center space-x-3'
                  onClick={() => downloadPackage("macos-universal")}
                >
                  <FaApple className='text-primaryColor text-2xl' />
                  <span>macOS (Intel)</span>
                </button>
                {/* Windows Download Button */}
                <button
                  className='py-3 px-6  text-primaryColor rounded-lg font-medium shadow-sm border-1 hover:border-hoverPrimary  flex items-center space-x-3'
                  onClick={() => downloadPackage("windows")}
                >
                  <FaWindows className='text-primaryColor text-2xl' />
                  <span>Windows</span>
                </button>
              </div>
            </li>

            <li>
              Sign in with username provided by Recant and connect your chat
              apps to Recant.
              {extractedMatrixUser && (
                <button
                  onClick={() => copyToClipboard(extractedMatrixUser)}
                  className={`flex text-start w-full items-center gap-2 bg-[#f5f5f5] p-3 rounded border mt-2 ${
                    copied
                      ? "border-hoverPrimary text-hoverPrimary"
                      : "border-gray-300 text-gray-500"
                  } hover:border-hoverPrimary`}
                  title='Copy link'
                >
                  <FiCopy className='text-lg' />
                  <p className='text-md flex-1 flex-wrap truncate'>
                    {extractedMatrixUser}
                  </p>
                </button>
              )}
            </li>
          </ol>
        </div>

        {/* Footer Button */}
        <div className='mt-8 w-full flex justify-center px-12'>
          <button
            onClick={handleCloseModal}
            className='py-2 px-6  text-white rounded-lg font-bold shadow-lg  transition duration-300 bg-primaryColor w-full'
          >
            &larr; Back to Dashboard
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default BeeperComponent;
